import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'

// Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyB91LGCgJHZ8vbtnlukDLc0qKgQssa8E7s',
  authDomain: 'home-sewn-leather.firebaseapp.com',
  projectId: 'home-sewn-leather',
  storageBucket: 'home-sewn-leather.appspot.com',
  messagingSenderId: '347754818266',
  appId: '1:347754818266:web:a9566b6a89816975d9d3e3',
  measurementId: 'G-0GF5P2P3XS',
  databaseURL: 'https://home-sewn-leather-default-rtdb.firebaseio.com'
}

// Initialize firebase and database
const app = initializeApp(firebaseConfig)
const db = getFirestore(app)

export { db, app }