import './Order.scss'
import { useState, useEffect } from 'react'
import { useParams, useNavigate } from "react-router-dom"

function Order() {
    
    const sessionID = useParams().session_id
    const navigate = useNavigate()
    
    return (<div className='order-page'>

        Order Info
    </div>)
}

export default Order