import InstagramIcon from '@mui/icons-material/Instagram'
import FacebookIcon from '@mui/icons-material/Facebook'
import TwitterIcon from '@mui/icons-material/Twitter'
import { Link } from 'react-router-dom'
import './Footer.scss'

const instagramPage = 'https://www.instagram.com/'
const facebookPage = 'https://www.facebook.com/'
const twitterPage = 'https://twitter.com/'

function Footer() {
    return (
    <div className='footer-component'>
        
        <Link className='link' to='/contact'>
            <p className='contact-link'>Contact</p>
        </Link>

        <p className='copyright'>© HomeSewnLeather 2024</p>

        {/* <div className='social-media-icons'>
            <a href={instagramPage} target="_blank" rel="noopener noreferrer">
                <InstagramIcon/>
            </a>

            <a href={facebookPage} target="_blank" rel="noopener noreferrer">
                <FacebookIcon/>
            </a>

            <a href={twitterPage} target="_blank" rel="noopener noreferrer">
                <TwitterIcon/>
            </a>
        </div> */}
    </div>
    )
}

export default Footer;