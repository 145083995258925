import './NotFound.scss'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

function NotFound() {

    return (
    <div className='not-found-page'>
        <ErrorOutlineIcon className='not-found-icon'/>
        <h1 className='not-found-text'>Sorry, page not found</h1>
    </div>)
    


}

export default NotFound