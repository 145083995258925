import './Account.scss'
import { useEffect, useContext, useState } from 'react'
import { doc, getDoc } from "firebase/firestore"
import { getAuth  } from "firebase/auth"
import { AuthContext } from '../contexts/Auth.js'
import { db } from '../Firebase.js'
import Loading from '../components/Loading.js'
import EastIcon from '@mui/icons-material/East'

function Account() {
    
    // Consts
    const user = useContext(AuthContext)
    const auth = getAuth()
    
    // States
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState('')
    const [userData, setUserData] = useState(null)
    const [orderData, setOrderData] = useState([])

    // Takes in a shipping status string and returns the proper color
    function GetShipColor(shipStatus) {

        if (shipStatus === 'In Progress') {
            return 'gray'
        } else if (shipStatus === 'Cancelled') {
            return 'red'
        } else {
            return 'green'
        }
    }

    // Returns jsx to display all orders
    function DisplayOrders() {
        
        let orders = []

        orderData.forEach((order, index) => {
            orders.push(<div className='order' key={'order' + index}>
                
            <div className='order-left'>
                <div className='order-number'>
                    Order {order?.orderNum}
                </div>

                <button className='view-order-button'>
                    <p className='view-order-button-text'>VIEW ORDER</p>
                    <EastIcon className='right-arrow-icon'/>
                </button>
                
            </div>
            
            <div className='order-middle'>
                <div className='order-ship-status-circle' style={{background:(GetShipColor(order?.status))}}/>
                
                <p className='order-ship-status-text'>
                    {order?.status}
                </p>
            </div>
            
            <p className='order-right'>
                {Object.keys(order?.items).length} item{Object.keys(order?.items).length > 1 && "s"}
            </p>

            </div>)
        })

        // If orders exist, then display them
        if (orders.length !== 0) {
            return orders
        } 
        
        // If no orders, then say so
        return <div className='no-orders-container'><p className='no-orders-text'>No orders</p></div>
    }

    // Converts seconds to readable date
    function ConvertSecondsToReadableDate(seconds) {
        const date = new Date(seconds * 1000)
    
        const options = {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
        };
    
        return date.toLocaleString('en-US', options);
    }

    // Returns jsx to display all messages
    function DisplayMessages() {
        let messages = [];

        // Sorting messages based on the 'date' property
        const sortedMessages = Object.values(userData?.messages || {}).sort((a, b) => b.date.seconds - a.date.seconds)


        sortedMessages.forEach((message, index) => {
            messages.push(
                <div className='message' key={'message' + index}>
                    
                    <div className='message-top'>
                        <p className='message-top-text'>From {message.from}</p>
                        <p className='message-top-text'>{ConvertSecondsToReadableDate(message.date.seconds)}</p>
                    </div>

                    <div className='message-divider-line'>

                    </div>

                    <p className='message-bottom'>
                        {message.content}
                    </p>
                    
                </div>
            )
        })

        // If messages exist, then display them
        if (messages.length !== 0) {
            return messages
        } 

        // If no messages, then say so
        return (
            <div className='no-messages-container'>
                <p className='no-messages-text'>No messages</p>
            </div>
        )
    }

    // When page is loaded, get user data and user orders
    useEffect(() => {
        
        // Fills userData and orderData
        async function GetAccountData() {
            try {

                // Fetch user data
                const userRef = doc(db, 'users', user.uid)
                const userSnapshot = await getDoc(userRef) // CAUSING POST ERROR IN CONSOLE????
                if (!userSnapshot.exists()) {throw new Error('Could not find user data')}
                
                // Set user data
                const data = userSnapshot.data()
                setUserData(data)

                // Fetch order data
                const orderRefs = data.orders || {}
                const orderRefArray = Object.values(orderRefs)

                // Use Promise.all to fetch all documents concurrently
                const orderDataArray = await Promise.all(
                    orderRefArray.map(async (orderRef) => {
                        const orderSnapshot = await getDoc(orderRef)
                        return orderSnapshot.exists() ? orderSnapshot.data() : null
                    })
                )
                
                // And set order data
                setOrderData(orderDataArray)

            } catch (error) {
                // If some error occured, set error
                setError(error)
            }
            setLoading(false)
        }

        GetAccountData()

    }, [user.uid])

    // When loading, show wheel
    if (loading) {return <Loading/>}

    // If failed to load, display error text
    if (!loading && error) {
        return (<div>
            <p className='error-text'>{error}</p>
        </div>)
    }

    // If loading successful, display page
    return (<div className='account-page'>
        
            {/* Profile Section */}
            <div>
                <h2 className='profile-header'>Profile</h2>
                <div className='profile-content'>
                    <div className='user-details'>
                        <p>Name: {userData.firstName} {userData.lastName}</p>
                        <p>Email: {user.email}</p>
                    </div>
                </div>
            </div>

            {/* Order Section */}
            <div>
                <h2 className='orders-header'>Orders</h2>
                <div className='orders-content'>
                    {DisplayOrders()}
                </div>
            </div>

            
            {/* Messages Section */}
            <div>
                <h2 className='messages-header'>Messages</h2>
                <div className='messages-content'>
                    {DisplayMessages()}
                </div>
            </div>

            <div className='logout-button-container'>
                <button className='logout-button' onClick={() => {auth.signOut()}}>
                    <p className='logout-button-text'>Logout</p>
                </button>
            </div>
    </div>)
}

export default Account