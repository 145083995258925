import './Landing.scss'
import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import DownArrow from '@mui/icons-material/KeyboardDoubleArrowDown'

// Landing Product
import WalletWithBox from '../images/landing-product-lowres.png'

// Box
import BoxClosed from '../images/boxes/box-closed-lowres.png'
import BoxOpen from '../images/boxes/box-open-lowres.png'

// Inside Box
import Burnisher from '../images/inbox/burnisher.png'
import Vial from '../images/inbox/vial.png'
import Glue from '../images/inbox/glue.png'
import Needles from '../images/inbox/needles.png'
import Thread from '../images/inbox/thread.png'
import Leather from '../images/inbox/leather.png'

// Person Crafting
import crafter from '../images/crafter.png'

// Final Product
import FinalProduct from '../images/final-product.png'

function Landing() {
    
    const [scrollOffset, setScrollOffset] = useState(0)
    const [includesVisible, setIncludesVisible] = useState(false)
    const [finalProdVisible, setFinalProdVisible] = useState(false)
    const navigate = useNavigate()

    // Adds listener for scrolling
    useEffect(() => {
        
        // Add listener for scroll
        const handleScroll = () => {setScrollOffset(window.scrollY)}
        window.addEventListener('scroll', handleScroll)

        // // Find when includes starts
        // const includesBar = document.querySelector('.includes-bar')
        // if (includesBar) {
        //     const rect = includesBar.getBoundingClientRect()
        //     setIncludesStart(rect.top)
        // }

        // // Find when video starts
        // const videoBar = document.querySelector('.video-bar')
        // if (videoBar) {
        //     const rect = videoBar.getBoundingClientRect()
        //     setVideoStarts(rect.top)
        // }
        
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    // Tells if user scrolled far enough to see box of included items
    useEffect(() => {
        
        // Includes observer
        const includesObserver = new IntersectionObserver(
          ([entry]) => {
            setIncludesVisible(entry.isIntersecting)
          },
          {
            threshold: 0.5
          }
        )
        const includes = document.querySelector('.includes');
        if (includes) {
            includesObserver.observe(includes);
        }
    
        // Final Prod observer
        const finalProdObserver = new IntersectionObserver(
            ([entry]) => {
              setFinalProdVisible(entry.isIntersecting)
            },
            {
              threshold: 0.3
            }
          )
          const finalProd = document.querySelector('.final-product');
          if (finalProd) {
            finalProdObserver.observe(finalProd);
          }

        return () => {includesObserver.disconnect();finalProdObserver.disconnect()}
    }, [])

    
    return (<div className='landing-page'>
        
        {/* Box and tagline */}
        <div className='wallet-box-container' style={{ transform: `translateY(${scrollOffset / 4}px)` }}>
            <h2 className='tagline'>A leather kit with all you need</h2>
            
            <img 
                src={WalletWithBox}
                alt='wallet'
                className='wallet'
            />
        </div>

        {/* Includes bar */}
        <div className='includes-bar' >
            <DownArrow className={scrollOffset === 0 ? 'down-arrow' : 'down-arrow invisible'}/>
        </div>

        {/* Includes */}
        <div className='includes'>

            <div className='box'>
                <img 
                    src={BoxClosed}
                    alt='box-closed'
                    className={!includesVisible ? 'box-closed show' : 'box-closed hidden'}
                />
                <img 
                    src={BoxOpen}
                    alt='box-open'
                    className={!includesVisible ? 'box-open hidden' : 'box-open show'}
                />
            </div>

            <div className='includes-grid'>
                <div className={!includesVisible ? 'item hidden' : 'item show'}>
                    <img className='item-photo'
                        src={Burnisher}
                        alt='burnisher'
                    />
                    <h3 className='item-name'>Burnisher</h3>
                </div>

                <div className={!includesVisible ? 'item hidden' : 'item show'}>
                    <img className='item-photo'
                        src={Vial}
                        alt='coat'
                    />
                    <h3 className='item-name'>Edge Kote</h3>
                </div>
                <div className={!includesVisible ? 'item hidden' : 'item show'}>
                    <img className='item-photo'
                        src={Glue}
                        alt='glue'
                    />
                    <h3 className='item-name'>Glue</h3>
                </div>
                <div className={!includesVisible ? 'item hidden' : 'item show'}>
                    <img className='item-photo'
                        src={Needles}
                        alt='needle'
                    />
                    <h3 className='item-name'>Needles</h3>
                </div>
                <div className={!includesVisible ? 'item hidden' : 'item show'}>
                    <img className='item-photo'
                        src={Thread}
                        alt='thread'
                    />
                    <h3 className='item-name'>Waxed Thread</h3>
                </div>
                <div className={!includesVisible ? 'item hidden' : 'item show'}>
                    <img className='item-photo'
                        src={Leather}
                        alt='leather'
                    />
                    <h3 className='item-name'>Precut Leather</h3>
                </div>
            </div>

        </div>

        {/* Video bar */}
        <div className='video-bar'> 
            <h3 className='video-caption'>Follow detailed video and paper instructions</h3>
        </div>

        {/* Video */}
        {/* <video 
            muted 
            controls={false} 
            loop 
            className='video'>
            <source src={Broll} type="video/mp4" />
        </video> */}
        <img src={crafter}
            alt='crafting'
            className='video'>
        </img>

        {/* Video bar */}
        <div className='end-bar'> 
            <h3 className='end-caption'>Finish with a beautiful product and new experience</h3>
        </div>
        
        <img 
            src={FinalProduct}
            alt='final-product'
            className={finalProdVisible ? 'final-product show' : 'final-product hidden'}
        />

        <div className='view-products-button-container'>
            <button className='view-products-button' onClick={() => {navigate('/products')}}>
                <h3 className='view-products-text'>View Kits</h3>
            </button>
        </div>



    </div>)
}

export default Landing

// const observer = new IntersectionObserver((entries) => {
//     entries.forEach((entry) => {
//         if (entry.isIntersecting) {
//             entry.target.classList.add('show')
//         } else {
//             entry.target.classList.remove('show')
//         }
//     })
// })