import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home'
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag'
import InfoIcon from '@mui/icons-material/Info'
import EmailIcon from '@mui/icons-material/Email'
import React from 'react'
import { Link } from 'react-router-dom'
import './MobileNav.scss'

function HamburgerButton(props) {
    return (
        <button className={'hamburger-button'}
                onClick={()=>{
                    props.setMobileNavOpen(!props.mobileNavOpen)
                    
                    // If user opened mobile nav while cart open, close cart
                    if (!props.mobileNavOpen)
                    {
                        props.setCartOpen(false)
                    }}}>
            <MenuIcon/>
        </button>
    )
}

function MobileNav(props) {
    return (
        <div>
            {/* Links for mobile */}
            <nav className={props.mobileNavOpen ? 'mobile-nav open' : 'mobile-nav'}>

                {/* When any link is clicked, we close mobile nav enable scrolling again (except on cart) */}

                <Link className='mobile-nav-item' to='/' onClick={()=>{props.setMobileNavOpen(!props.mobileNavOpen)}}>
                    <HomeIcon className='mobile-nav-item-icon'/>
                    <h3>Home</h3>
                </Link>

                <Link className='mobile-nav-item' to='/products' onClick={()=>{props.setMobileNavOpen(!props.mobileNavOpen)}}>
                    <ShoppingBagIcon className='mobile-nav-item-icon'/>
                    <h3>Products</h3>
                </Link>

                <Link className='mobile-nav-item' onClick={()=>{props.setMobileNavOpen(!props.mobileNavOpen); props.setCartOpen(true)}}>
                    <ShoppingCartIcon className='mobile-nav-item-icon'/>
                    <h3>Cart</h3>
                </Link>

                <Link className='mobile-nav-item' to='/account' onClick={()=>{props.setMobileNavOpen(!props.mobileNavOpen)}}>
                    <AccountCircleIcon className='mobile-nav-item-icon'/>
                    <h3>Account</h3>
                </Link>

                <Link className='mobile-nav-item' to='/about' onClick={()=>{props.setMobileNavOpen(!props.mobileNavOpen)}}>
                    <InfoIcon className='mobile-nav-item-icon'/>
                    <h3>About</h3>
                </Link>

                <Link className='mobile-nav-item' to='/contact' onClick={()=>{props.setMobileNavOpen(!props.mobileNavOpen)}}>
                    <EmailIcon className='mobile-nav-item-icon'/>
                    <h3>Contact</h3>
                </Link>

            </nav>

            {/* Blurs screen for mobile nav when drawer is out */}
            <button 
                className={props.mobileNavOpen ? 'screen-blur visible' : 'screen-blur'}
                onClick={()=>{props.setMobileNavOpen(!props.mobileNavOpen)}}
            />
        </div>
    )
}

export { HamburgerButton, MobileNav }