import React, { useContext } from 'react'
import { AuthContext } from '../contexts/Auth.js'
import { Navigate } from 'react-router-dom'

function Protected({ children }) {
    const user = useContext(AuthContext)

    // Redirect to login if the user is not signed in
    if (!user) {
    return <Navigate to ='/login'/>
    }

    // Render the protected content if the user is signed in
    return <>{children}</>
}

export default Protected