import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from "react-router-dom"
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import FlagIcon from '@mui/icons-material/Flag'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import Loading from '../components/Loading.js'
import './Product.scss'
import { doc, getDoc } from "firebase/firestore"
import { db } from '../Firebase.js'
import NotFound from '../pages/NotFound.js'
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Quality from '@mui/icons-material/Verified'

function Product(props) {
    const productNameKebab = useParams().product
    const productNameCamel = KebabToCamel(productNameKebab)
    const materialNameKebab = useParams().material
    const materialNameCamel = KebabToCamel(materialNameKebab)
    
    const navigate = useNavigate()

    const [materialPreviews, setMaterialPreviews] = useState(null)
    const [product, setProduct] = useState(null)
    const [productExists, setProductExists] = useState(true)
    const [loading, setLoading] = useState(true)
    const [selectedImage, setSelectedImage] = useState(0)
    

    // Get products from backend when the page is loaded
    useEffect(() => {
    
        async function GetProducts() {
            // Get product and set it
            const productRef = doc(db, 'products', productNameCamel)
            const productInfo = await getDoc(productRef)
            setProduct(productInfo.data())
            
            // If the product or materal in the url doesn't exist, indicate so
            if (!productInfo.exists() || !(materialNameCamel in productInfo.data().materials)) {
                setLoading(false)
                setProductExists(false)
                return
            }

            // Get material preview images and set them
            const materialsRef = doc(db, 'materials', 'images')
            const previews = await getDoc(materialsRef)
            setMaterialPreviews(previews.data())
            
            setLoading(false)
        }
        GetProducts()
    }, [materialNameCamel, productNameCamel])

    // Converts kebab-case to camelCase
    function KebabToCamel(str) {
        return str.replace(/-([a-z])/g, (_, letter) => letter.toUpperCase());
    }    

    // Converts from camelCase to kebab-case
    function CamelToKebab(str) {
        return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
    }

    // Converts from kebab-case to Pascal Case with spaces
    function kebabToPascalWithSpaces(str) {
        return str
            .split('-')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    // Converts seconds to readable date
    function ConvertSecondsToReadableDate(date) {
    
        const options = {
            month: 'short',
            day: 'numeric',
        };
    
        return date.toLocaleString('en-US', options);
    }

    // Calculate shipping duration
    function GetShippingTimeRange() {
        const currentDate = new Date();

        const handlingTime = 2

        // Get a date two days in the future
        const lowerRange = new Date(currentDate);
        lowerRange.setDate(currentDate.getDate() + handlingTime + 2);
        const upperRange = new Date(currentDate);
        upperRange.setDate(currentDate.getDate() + handlingTime + 8);

        return ConvertSecondsToReadableDate(lowerRange) + ' and ' + ConvertSecondsToReadableDate(upperRange)
    }

    // Adds item to cart and opens cart
    function AddItemToCart() {
        
        // Open cart
        props.setCartOpen(true)
        
        // If this combination of product and material is in stock
        if (IsInStock()) {
            let cart = window.localStorage.getItem("cart") ? JSON.parse(window.localStorage.getItem("cart")) : []
            
            // Check to see if that item is already in cart; if so then increase quantity
            for (let i = 0; i < cart.length; i++) {
                // If an item exists
                if (cart[i].type === productNameCamel &&
                    cart[i].material === materialNameCamel) {
                    
                    // If product is not in stock or user already has 10 items in cart, then just return
                    if (product.materials[materialNameCamel].stock < cart[i].quantity + 1 ||
                        cart[i].quantity + 1 > 10) {return}

                    cart[i].quantity += 1
                    window.localStorage.setItem("cart", JSON.stringify(cart))
                    return
                }
            }

            // Otherwise add item to cart
            let item = {}
            item.type = productNameCamel
            item.material = materialNameCamel
            item.quantity = 1
            cart.push(item)

            window.localStorage.setItem("cart", JSON.stringify(cart))
        }
    }

    // Returns info indicating if product is in stock
    function IsInStock() {
        return product.materials[materialNameCamel]?.stock !== 0
    }

    if (loading) {return <Loading/>}

    if (!productExists) {return <NotFound/>}

    return (
    <div className='product-page'>
        
        {/* Contains all images */}
        <div className='product-images'>
            
            {/* Featured image */}
            <img 
                src={product?.images?.[selectedImage]}
                alt='featured'
                className='featured-image'
                width={'500px'}
            />

            {/* Images selector */}
            <div className='selector'>
                {product && Object.keys(product?.images).map((i)=> {
                    return (
                        <input
                            type='image'
                            key={'preview-image-' + i}
                            src={product?.images?.[i]}
                            alt=''
                            width={'96px'}
                            onClick={() => {setSelectedImage(i)}}
                            className={selectedImage === i ? 'selected-preview-image' : 'preview-image'}
                        />
                    )})
                }
            </div>
        </div>
        
        {/* Contains all text */}
        <div className='product-text'>
            
            {/* Product Name */}
            <h1>{product?.name}</h1>
                
            {/* Price */}
            <h2>
                ${product.price}.00 USD
            </h2>

            {/* Material */}
            <h4>Material:&nbsp;{kebabToPascalWithSpaces(materialNameKebab)}</h4>
                
            {/* Material Selector (only show if more than 1 material available) */}
            {Object.keys(product.materials).length > 1 && 
                <div className='material-selector'>
                    {Object.keys(product.materials)
                        .sort() // Sort the keys alphabetically
                        .map(materialType => (
                            <img
                                key={materialType}
                                alt={materialType}
                                src={materialPreviews[materialType]}
                                className={(materialType === materialNameCamel) ? 'material-image-selected' : 'material-image'}
                                width={'30px'}
                                onClick={() => {navigate('/products/' + productNameKebab + '/' + CamelToKebab(materialType))}}
                            />
                        ))
                    }
                </div>
            }

            {/* Add to cart button */}
            <div className='add-to-cart'>
                {IsInStock() ? <button
                    className='add-to-cart-button'
                    onClick={()=> {AddItemToCart()}}>
                    <p>Add to Cart</p>
                </button> 
                :
                <button
                    className='add-to-cart-button'>
                    <p>Sold Out</p>
                </button>}
            </div>
            
            {/* Description */}
            <p>{product?.description}</p>
            
            {/* Icons */}
            <div className='product-detail-with-icon'>
                <LocalShippingIcon/>
                <p>Will arrive between {GetShippingTimeRange()}</p>
            </div>
            <div className='product-detail-with-icon'>
                <AccessTimeIcon/>
                <p>Time To Complete: {product.timeToComplete}</p>
            </div>
            <div className='product-detail-with-icon'>
                <Quality/>
                <p>Lifetime Warranty</p>
            </div>
        </div>
    </div>)
}

export default Product