import './Contact.scss'
import Mail from '@mui/icons-material/MailOutline';
import './CreateAccount.scss'
import { Link } from 'react-router-dom'
import Checkbox from '@mui/material/Checkbox'
import { useNavigate } from "react-router-dom"
import { doc, setDoc, collection } from "firebase/firestore"
import { db } from '../Firebase.js'
import './Login.scss'
import React, { useState } from 'react'
import TextField from '@mui/material/TextField'


function Contact() {
    
    // States
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState('')
    const [subject, setSubject] = useState('')
    const [subjectError, setSubjectError] = useState('')
    const [body, setBody] = useState('')
    const [bodyError, setBodyError] = useState('')
    
    const [messageSuccess, setMessageSuccess] = useState('')
    const [messageError, setMessageError] = useState('')

    function SendContact() {

        let validInputs = true

        // Check email entered
        if (email === '') {
            setEmailError('Please fill in field')
            validInputs = false
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            setEmailError('Please enter a valid email address');
            validInputs = false;
        }

        // Check email entered
        if (subject === '') {
            setSubjectError('Please fill in field')
            validInputs = false
        }

        // If inputs invalid, don't try to login
        if (body === '') {
            setBodyError('Please fill in field')
            validInputs = false
        }

        if (!validInputs) {
            return
        }
        
        // Send contact info to DB
        const contactsRef = collection(db, 'contacts') // Reference to the 'contacts' collection
        const newContactRef = doc(contactsRef) // Reference to a new document in the 'contacts' collection

        // Set the data of the new document
        setDoc(newContactRef, {
            email: email,
            subject: subject,
            body: body,
            timestamp: new Date()
        }).then(() => {
            setMessageSuccess('Message successfully sent!');
            console.log('Message sent successfully!');
        }).catch((error) => {
            setMessageError('Error sending message!');
            console.log('Message failed to send.');
        });
        
    }

    return (<div className='contact-page'>

        <div className='contact-header'>
            <Mail/><h2>Contact Us</h2>
        </div>

        <p className='contact-info'>To resolve issues with orders or for any other inquiries, please fill out the form below. A response should be returned to you soon.</p>
        
        <div className='input-boxes'>

            <TextField 
                label='Email'
                variant='standard'
                className='text-box'
                error={emailError !== ''}
                helperText={emailError}
                onChange={(event) => {
                    setEmail(event.target.value)
                    
                    setEmailError('')
                    setSubjectError('')
                    setBodyError('')
                    setMessageSuccess('')
                    setMessageError('')
                }}
                inputProps={{ maxLength: 30 }}
            />

            <TextField 
                label='Subject'
                variant='standard'
                className='text-box'
                error={subjectError !== ''}
                helperText={subjectError}
                onChange={(event) => {
                    setSubject(event.target.value)
                    
                    setEmailError('')
                    setSubjectError('')
                    setBodyError('')
                    setMessageSuccess('')
                    setMessageError('')
                   
                }}
                inputProps={{ maxLength: 50 }}
            />

            <TextField 
                label='Message'
                variant='standard'
                multiline
                maxRows={6}
                className='text-box'
                error={bodyError !== ''}
                helperText={bodyError}
                onChange={(event) => {

                    setBody(event.target.value)
                    setEmailError('')
                    setSubjectError('')
                    setBodyError('')
                    setMessageSuccess('')
                    setMessageError('')
                }}
                inputProps={{ maxLength: 500 }}
            />
            
            <button className='send-button' 
                    onClick={()=> {SendContact()}}>
                <p className='send-button-text'>Send</p>
            </button>

            {messageSuccess && <h3 className='message-success-text'>
               {messageSuccess} 
            </h3>}


            {messageError && <h3 className='message-error-text'>
               {messageError} 
            </h3>}

        </div>



    </div>)
}

export default Contact