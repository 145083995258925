import './Login.scss'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import TextField from '@mui/material/TextField'
import { getAuth, signInWithEmailAndPassword  } from "firebase/auth"

function Login() {
    
    // States
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState('')
    const [password, setPassword] = useState('')
    const [passwordError, setPasswordError] = useState('')
    
    // Consts
    const navigate = useNavigate()
    const auth = getAuth()

    function LoginOnDatabase() {

        let validInputs = true

        // Check email entered
        if (email === '') {
            setEmailError('Please fill in field')
            validInputs = false
        }

        // Check email entered
        if (password === '') {
            setPasswordError('Please fill in field')
            validInputs = false
        }

        // If inputs invalid, don't try to login
        if (!validInputs) {return}

        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // const user = userCredential.user

            navigate('/account')
        })
        .catch((error) => {

            setPasswordError('Email or password incorrect')
            setEmailError('Email or password incorrect')
        })
    }

    return (<div className='login-page'>
        
        {/* Main Title */}
        <Link className='title' to='/'>
            <h1>HomeSewnLeather</h1>
        </Link>
        
        <h2>
            Login to manage orders
        </h2>
        
        <TextField 
            id='email-input'
            label='Email'
            variant='standard'
            className='input-box'
            error={emailError !== ''}
            helperText={emailError}
            onChange={(event) => {
                setEmail(event.target.value)
                setEmailError('')
                setPasswordError('')
              }}
        />
        <TextField 
            id='password-input'
            label='Password'
            type='password'
            variant='standard'
            className='input-box'
            error={passwordError !== ''}
            helperText={passwordError}
            onChange={(event) => {
                setPassword(event.target.value)
                setPasswordError('')
                setEmailError('')
              }}
        />

        <button className='login-button' 
                onClick={()=> {LoginOnDatabase()}}>
            <p className='login-button-text'>Login</p>
        </button>
        

        {/* Create account suggestion  */}
        <button className='create-account-suggestion' onClick={() => {navigate('/create-account')}}>
            <p className='create-account-suggestion-text'>Create an account instead</p>
        </button>

    </div>)
}

export default Login