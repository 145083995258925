import React, { useState, useEffect } from 'react'
import './Products.scss'
import { useNavigate } from "react-router-dom"
import { getDocs, collection } from "firebase/firestore"
import { db } from '../Firebase.js'
import Loading from '../components/Loading.js'

function Products(props) {
    
    const [loading, setLoading] = useState(true)
    const [products, setProducts] = useState({})
    const navigate = useNavigate()
    const urlParams = new URLSearchParams(window.location.search)
    const openCart = urlParams.get('opencart')

    // Get products from backend when the page is loaded
    useEffect(() => {
    
        async function GetProducts() {
            try {
                const productsRef = collection(db, 'products')
                const productsSnapshot = await getDocs(productsRef)
                let productsObj = {}
                
                productsSnapshot.forEach(doc => {
                    productsObj[doc.id] = doc.data()
                })
        
                setProducts(productsObj)
                setLoading(false)
            } catch (e) {
                console.log(e)
            }
        }
    
        GetProducts()

        // If checkout was cancelled, then open cart back up
        if (openCart) {
            props.setCartOpen(true)
        }
        // eslint-disable-next-line
    }, [])
    
    // Converts from camelCase to kebab-case
    function CamelToKebab(str) {
        return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
    }

    // Checks all materials of the product to see if they are out of stock
    function IsOutOfStock(materials) {
        return Object.values(materials).every(material => {
            return material.stock <= 0
        })
    }

    // Returns an image of the product with name and price
    function GetProductPreview(productName, i) {
        const product = products[productName]
        const materials = product.materials

        return (<div className='product' key={'product-' + i + '-container'}>
            
            {/* Image */}
            <input
                className='product-image-front'
                key={'product-' + i}
                width={'100%'}
                type="image"
                src={product?.images?.[0]}
                alt=''
                onClick={() => {navigate('/products/' + CamelToKebab(productName) + '/english-tan')}}
            /> 
            <input
                className='product-image-back'
                key={'product-' + i + '-hover'}
                width={'100%'}
                type="image"
                src={product?.images?.[1] ? 
                    product?.images?.[1] : 
                    product?.images?.[0]}
                alt=''
                onClick={() => {navigate('/products/' + CamelToKebab(productName) + '/english-tan')}}
            /> 

            {/* Show sold out badge if necessary */}
            {/* {IsOutOfStock(materials) &&
            <div className='soldout-badge'>
                <div className='soldout-text'>
                    Sold Out
                </div>
            </div>} */}
            
            {/* Name */}
            <div className='product-text' key={'product-' + i + '-name'}>
                {product?.name}
            </div>

            {/* Price */}
            <div className='product-text' key={'product-' + i + '-price'}>
                ${product?.price}
            </div>
        </div>)
    }

    // When loading, show wheel
    if (loading) {return <Loading/>}
    
    // If loaded, show page
    return (<div className='products-page'>

        {/* Display all products */}
        {(products && Object.keys(products).length !== 0) ?
        <div className='product-grid'>
            {Object.keys(products).map((product, i) => (GetProductPreview(product, i)))}
        </div>
        :
        <div className='no-products-text'>
            No products found.
        </div>}
    </div>)
}

export default Products