import React, { useState, useEffect } from 'react'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import CloseIcon from '@mui/icons-material/Close'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import './Cart.scss'
import { getDocs, collection } from "firebase/firestore"
import { db } from '../Firebase.js'
import { useNavigate } from "react-router-dom"

function Cart(props) {
    const [cart, setCart] = useState([])
    const [products, setProducts] = useState(null)
    const [loading, setLoading] = useState(true)
    const [stockAlert, setStockAlert] = useState('')
    const [checkoutError, setCheckoutError] = useState('')
    const navigate = useNavigate()

    // Whenever the cart is opened, update product data and items in cart
    // Get products from backend when the page is loaded
    useEffect(() => {
    
        async function GetProducts() {
            try {
                const productsRef = collection(db, 'products')
                const productsSnapshot = await getDocs(productsRef)
                let productsObj = {}
                
                productsSnapshot.forEach(doc => {
                    productsObj[doc.id] = doc.data()
                })
                
                // Set updated products
                setProducts(productsObj)

                // Try to get cart from localstorage; if this fails, then cart might be corrupt; reset it
                try {
                    setCart(window.localStorage.getItem('cart') ? JSON.parse(window.localStorage.getItem('cart')) : []) // Update cart state
                } catch (e) {
                    window.localStorage.setItem('cart', JSON.stringify([]))
                }

                // After products are set, check to make sure valid quantities are in the cart                
                CheckCartQuantitiesValid()

            } catch (e) {
                console.log(e)
            }
        }

        GetProducts() // Get updated product info

        setLoading(false)
    }, [props.cartOpen])

    // Creates a new checkout session with stripe
    async function Checkout() {
        try {

            const response = await fetch('http://localhost:4000/create-checkout-session', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(cart),
            })
    
            if (response.ok) {
                const data = await response.json()
                window.location = data.url
            } else {
                setCheckoutError('There was a problem checking out. Please refresh and try again. If that does not work then reset cart.')
            }
        } catch (e) {
            console.error(e)
            setCheckoutError('There was a problem checking out. Please refresh and try again. If that does not work then reset cart.')
        }
    }

    // Returns the subtotal cost for all items in cart, in USD
    function GetSubtotal() {
        if (!cart || !products) {
            return 0
        }
    
        try {
            let sum = 0
            cart.forEach(item => {
                const product = products[item.type]
        
                if (product) {
                    sum += item.quantity * product.price
                } else {
                    console.error(`Could not calculate subtotal`)
                }
            })
        
            return sum


        } catch (e) {
            window.localStorage.setItem('cart', JSON.stringify([]))
        }
    }

    // Converts string from camelCase to Pascal Case With Space
    function camelToPascalWithSpaces(inputString) {
        // Use regular expression to match camelCase pattern and insert spaces
        return inputString.replace(/([a-z])([A-Z])/g, '$1 $2')
                         .replace(/^./, function(str){ return str.toUpperCase() })
    }

    // Checks to make sure there is enough stock for order in cart
    function CheckCartQuantitiesValid() {
        let newCart = [...cart]

        // Check the quantity of every item agains what is stored in products state
        newCart.forEach(item => {
            if (products[item.type]?.materials[item.material]?.stock < item.quantity) {

                const qtyToReduceBy = item.quantity - products[item.type].materials[item.material].stock

                for (let i = 0; i < qtyToReduceBy; i++) {
                    DecreaseItemQuantity(item.type, item.material)
                    setStockAlert('Due to changes in stock, your cart has been modified.')
                }
            }
        })
    }

    // Increases item quantity by 1
    function IncreaseItemQuantity(itemName, itemMaterial) {
        let newCart = [...cart]; // Create a copy of the cart array
    
        // Find item in cart; then increase quantity
        for (let i = 0; i < newCart.length; i++) {
            if (newCart[i].type === itemName &&
                newCart[i].material === itemMaterial && 
                products[itemName].materials[itemMaterial].stock >= newCart[i].quantity + 1 &&
                newCart[i].quantity + 1 <= 10) {
                newCart[i].quantity += 1
                break
            }
        }
    
        window.localStorage.setItem('cart', JSON.stringify(newCart)) // Update localStorage with the copy
        setCart(newCart) // Update state with the copy
    }
    
    // Decreases item quantity by 1
    function DecreaseItemQuantity(itemName, itemMaterial) {
        let newCart = [...cart]; // Create a copy of the cart array
    
        // Find item in cart; then decrease quantity
        for (let i = 0; i < newCart.length; i++) {
            if (newCart[i].type === itemName && newCart[i].material === itemMaterial) {
                newCart[i].quantity -= 1
                if (newCart[i].quantity <= 0) {
                    newCart.splice(i, 1)
                }
                break
            }
        }
    
        window.localStorage.setItem('cart', JSON.stringify(newCart)) // Update localStorage with the copy
        setCart(newCart); // Update state with the copy
    }
    
    if (loading) {return <div/>}

    return (
        <div>
            <div className={props.cartOpen ? 'cart-component open' : 'cart-component'}>
            
                <h1 className='cart-title'>
                    <button className='exit-cart-button' onClick={() => {props.setCartOpen(false)}}>
                        <CloseIcon/>
                    </button>
                    Cart
                </h1>

                {/* List photo, details, and price for each product */}
                {products && cart && cart.length > 0 && Array.isArray(cart) && cart.map((item, i) => {
                    const product = products[item.type]
                    try {
                        return (
                            <div className='cart-item' key={'cart-item-' + i}>
                
                                {/* Image */}
                                <img 
                                    src={product?.images?.[0]} 
                                    width='100px' 
                                    height='100px' 
                                    alt=''
                                    className='cart-item-image'
                                    key={'cart-item-' + i + 'image'}
                                />
                                
                                <div className='cart-item-details'>
                                    
                                    {/* Name and Material of item */}
                                    <h4>{product?.name} - {camelToPascalWithSpaces(item?.material)}</h4>
                                    
                                    {/* Modifies quantity of item */}
                                    <div className='qty-selector'>
                                        
                                        {/* Minus button */}
                                        <button className='change-qty-button'
                                            onClick={()=> {DecreaseItemQuantity(item.type, item.material)}}>
                                            <RemoveIcon className='change-qty-icon'/>
                                        </button>
    
                                        {/* Quantity to add to cart */}
                                        <p className='cart-qty'>{item.quantity}</p>
    
                                        {/* Plus button */}
                                        {products[item.type].materials[item.material].stock > item.quantity && <button className='change-qty-button'
                                            onClick={()=> {IncreaseItemQuantity(item.type, item.material)}}>
                                            <AddIcon className='change-qty-icon'/>
                                        </button>}    
                                    </div>
                                </div>
    
                                {/* Price */}
                                <p className='cart-item-price'>
                                    ${product?.price}.00
                                </p>
                            </div>)
                    } catch (e) {
                        window.localStorage.setItem('cart', JSON.stringify([]))
                    }})}

                {/* If stock has been updated, alerts user */}
                {stockAlert && 
                <div className='stock-alert'>
                    <button onClick={() => {setStockAlert('')}}>
                        <CloseIcon/>
                    </button>
                    <p className='stock-alert-text'>
                        {stockAlert}
                    </p>
                </div>}

                {/* Subtotal */}
                {cart.length !== 0 && <div className='subtotal'>
                    Subtotal: ${GetSubtotal()}.00
                </div>}

                {/* Cart Empty logo and text */}
                {cart.length <= 0 && <div className='cart-empty'>
                    <ShoppingCartIcon className='cart-empty-icon'/>
                    <p className='cart-empty-text'>Your cart is currently empty.</p>
                </div>}
                
                {/* Checkout Button (Becomes view products button if cart is empty) */}
                {cart.length !== 0 ? <div className='checkout'>
                    <button className='checkout-button' onClick={() => {Checkout()}}>
                        Checkout
                    </button>
                </div>
                : 
                <div className='checkout'>
                    <button className='checkout-button' onClick={() => {navigate('/products');props.setCartOpen(false)}}>
                        View Products
                    </button>
                </div>}

                {/* If stock has been updated, alerts user */}
                {checkoutError && 
                <div className='checkout-error'>
                    <button onClick={() => {setCheckoutError('')}}>
                        <CloseIcon/>
                    </button>
                    <p className='checkout-error-text'>
                        {checkoutError}
                    </p>
                </div>}

            </div>

            {/* Blur when cart enabled */}
            <button 
                className={props.cartOpen ? 'screen-blur visible' : 'screen-blur'} 
                onClick={() => {props.setCartOpen(false)}}
            />
        </div>
    )
}

export default Cart;