import React, { useEffect, useState } from 'react'
import { getAuth } from 'firebase/auth'

const AuthContext = React.createContext()

function AuthProvider({ children }) {
    const [user, setUser] = useState(null)
    const auth = getAuth()

    // On state change, user state will be updated
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(setUser)

        // Cleanup function to unsubscribe when the component unmounts
        return () => unsubscribe()
    }, [auth])  // Add 'auth' to the dependency array

    // Provide user state to tree below
    return (
        <AuthContext.Provider value={user}>
            {children}
        </AuthContext.Provider>
    )
}

export { AuthContext, AuthProvider}
