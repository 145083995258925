import './Header.scss'
import { Link } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import Cart from '../components/Cart.js'
import { HamburgerButton, MobileNav } from './MobileNav.js'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'

function Header(props) {

    const [cart, setCart] = useState([])

    // Whenever the cart is opened, update product data and items in cart
    useEffect(() => {
        setCart(window.localStorage.getItem("cart") ? JSON.parse(window.localStorage.getItem("cart")) : [])
    }, [props.cartOpen])

    // Finds count of products added to cart
    function GetProductCount() {
        try {
            if (!cart || cart == []) {
                return 0
            }
        
            let sum = 0
            cart.forEach(item => {
                sum += item.quantity
            })
        
            return sum
            
        } catch (e) {
            return 0
        }
    }

    return (
        <div className='header-component'>
            
            {/* Includes title and icons */}
            <div className='upper-wrapper'>

                {/* Main Title */}
                <Link className='title' to='/'>
                    <h1>HomeSewnLeather</h1>
                </Link>
                
                {/* Icons */}
                <div className='icons'>

                    {/* Account Button */}
                    <Link className='account-button' to='/account'>
                        <AccountCircleIcon/>
                    </Link>

                    {/* Cart Button */}
                    <button className='cart-button' onClick={()=>{props.setCartOpen(!props.cartOpen)}}>
                        <ShoppingCartIcon/>
                        <span className='badge'>
                            {GetProductCount()}
                        </span>
                    </button>

                    {/* Cart Component */}
                    <Cart
                        cartOpen={props.cartOpen}
                        setCartOpen={props.setCartOpen}
                    />

                    {/* Hamburger Button (opens mobile nav menu) */}
                    <HamburgerButton 
                        mobileNavOpen={props.mobileNavOpen}
                        setMobileNavOpen={props.setMobileNavOpen}
                        setCartOpen={props.setCartOpen}
                    />
                </div>
            </div>

            {/* Links for desktop */}
            <div className='desktop-nav'>
                
                <div className='header-link'>
                    <Link className='link' to='/'>
                        <h3>Home</h3>
                    </Link>
                    <div className='header-underline'/>
                </div>

                <div className='header-link'>
                    <Link className='link' to='/products'>
                        <h3>Products</h3>
                    </Link>
                    <div className='header-underline'/>
                </div>

                <div className='header-link'>
                    <Link className='link' to='/about'>
                        <h3>About</h3>
                    </Link>
                    <div className='header-underline'/>
                </div>

                <div className='header-link'>
                    <Link className='link' to='/contact'>
                        <h3>Contact</h3>
                    </Link>
                    <div className='header-underline'/>
                </div>
            </div>

            {/* Links for mobile */}
            <MobileNav
                mobileNavOpen={props.mobileNavOpen}
                setMobileNavOpen={props.setMobileNavOpen}
                setCartOpen={props.setCartOpen}/>
        </div>
    )
}

export default Header