import './CreateAccount.scss'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import { useNavigate } from "react-router-dom"
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth"
import { doc, setDoc, collection } from "firebase/firestore"
import { db } from '../Firebase.js'

function CreateAccount() {
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [over13, setOver13] = useState(false)

    // Holds an array of strings, which are errors for each text field
    const [fieldErrors, setFieldErrors] = useState(['', '', '', '', '', ''])
    const [submitError, setSubmitError] = useState('')

    // Consts
    const navigate = useNavigate()
    const auth = getAuth()

    // Pass in the index of the field error, and error message
    function SetFieldError(index, message)
    {
        setFieldErrors((prevErrors) => {
            let arr = [...prevErrors]
            arr[index] = message
            return arr
        })
    }

    function CreateAccountOnDatabase() {

        let inputValid = true

        // Check firstname isn't empty
        if (firstName === '') {
            SetFieldError(0, "Please fill in field")
            inputValid = false
        }

        // Check email isn't empty
        if (email === '') {
            SetFieldError(2, "Please fill in field")
            inputValid = false
        }

        // Check password isn't empty
        if (password === '') {
            SetFieldError(3, "Please fill in field")
            inputValid = false
        }

        // Check confirm password isn't empty
        if (confirmPassword === '') {
            SetFieldError(4, "Please fill in field")
            inputValid = false
        }

        // Check password and confirm password are the same
        else if (password !== confirmPassword) {
            SetFieldError(4, "Must match password")
            inputValid = false
        }

        // Check password and confirm password are the same
        else if (password.length < 8 || confirmPassword.length < 8) {
            SetFieldError(3, "Password must be atleast 8 characters")
            SetFieldError(4, "Password must be atleast 8 characters")
            inputValid = false
        }
        
        // Make sure user is over 13
        if (!over13) {
            SetFieldError(5, "You must be over 13")
            inputValid = false
        }

        // If any of the inputs failed their checks, return
        if (!inputValid) {return}

        createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {

            // Set user data on firestore
            const usersRef = collection(db, 'users')
            const docRef = doc(usersRef, userCredential.user.uid)

            setDoc(docRef, {
                firstName: firstName,
                lastName: lastName,
                email: email,
            })



            // If successful, navigate to account page
            navigate('/account')
        })
        .catch((error) => {
            console.log(error)
            
            // Email already exists
            if (error.code === 'auth/email-already-in-use') {
                SetFieldError(2, 'Email already being used by existing user')
                return
            }
            
            // Email invalid
            if (error.code === 'auth/invalid-email') {
                SetFieldError(2, 'Invalid email')
                return
            }

            // Invalid password
            if (error.code === 'auth/invalid-password') {
                SetFieldError(2, 'Invalid email')
                return
            }

            setSubmitError('There was a problem creating your account')
        })
    }

    return (<div className='create-account-page'>
        
        {/* Main Title */}
        <Link className='title' to='/'>
            <h1>HomeSewnLeather</h1>
        </Link>
        
        <h2>
            Create Account
        </h2>

        {submitError && <p className='error-text'>{submitError}</p>}
        
        {/* First Name */}
        <TextField 
            id='firstname-input'
            label='First Name'
            variant='standard'
            className='input-box'
            error={fieldErrors[0] !== ''}
            helperText={fieldErrors[0]}
            onChange={(event) => {                
                setFirstName(event.target.value)
                SetFieldError(0, '')
              }}
        />

        {/* Last Name */}
        <TextField 
            id='lastname-input'
            label='Last Name (optional)'
            variant='standard'
            className='input-box'
            onChange={(event) => {
                setLastName(event.target.value)
              }}
        />

        {/* Email */}
        <TextField 
            id='email-input'
            label='Email'
            variant='standard'
            className='input-box'
            error={fieldErrors[2] !== ''}
            helperText={fieldErrors[2]}
            onChange={(event) => {
                setEmail(event.target.value)
                SetFieldError(2, '')
              }}
        />

        {/* Password */}
        <TextField 
            id='password-input'
            label='Password'
            type='password'
            variant='standard'
            className='input-box'
            error={fieldErrors[3] !== ''}
            helperText={fieldErrors[3]}
            onChange={(event) => {
                setPassword(event.target.value)
                SetFieldError(3, '')
                SetFieldError(4, '')
              }}
        />

        {/* Confirm Password */}
        <TextField 
            id='password-confirm-input'
            label='Confirm Password'
            type='password'
            variant='standard'
            className='input-box'
            error={fieldErrors[4] !== ''}
            helperText={fieldErrors[4]}
            onChange={(event) => {
                setConfirmPassword(event.target.value)
                SetFieldError(3, '')
                SetFieldError(4, '')
              }}
        />

        {/* Over 13 Years Old */}
        <div>
            <button
                className='over13'
                onClick={() => {
                    setOver13(!over13)
                    SetFieldError(5, '')
                }}>
                <Checkbox
                    id='over13-checkbox'
                    className='over13-checkbox'
                    checked={over13}
                    onChange={(event) => {
                        setOver13(event.target.checked)
                        SetFieldError(5, '')
                    }}
                />
                <div className='over13-text'>I am over 13</div>
            </button>

            {fieldErrors[5] !== '' && 
                <p className='create-account-error'>{fieldErrors[5]}</p>
            }
        </div>
        
        {/* Create account button */}
        <button className='create-account-button' 
                onClick={()=> {CreateAccountOnDatabase()}}>
            <p className='create-account-button-text'>Create Account</p>
        </button>

        {/* Login suggestion  */}
        <button className='login-suggestion' onClick={() => {navigate('/login')}}>
            <p className='login-suggestion-text'>Login with an existing account</p>
        </button>
        
    </div>)
}

export default CreateAccount