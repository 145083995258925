import './About.scss'
import Quality from '@mui/icons-material/Verified'
import Happy from '@mui/icons-material/EmojiEmotions'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'
import AboutBackground from '../images/about-br-lowres.png'

function About() {
    return (<div className='about-page'>

        <div className='about-text'>
            <h2 className='who-we-are'>Who We Are</h2>

            <p className='intro'>At HomeSewnLeather, we provide premium leather crafting kits for beginners to create their own leather products at home. More importantly though, we are advocates of creativity and the joy of leather crafting.</p>

            {/* Quality */}
            <div className='section-header'>
                <Quality className='header-icon'/> A Commitment to Quality
            </div>
            <div className='section-desc'>
                All HomeSewnLeather products come with a generous return policy. If you aren't happy with the product you've created, we will provide a full refund, no questions asked.
            </div>

            {/* Superior Materials */}
            <div className='section-header'>
                <EmojiEventsIcon className='header-icon'/>Superior Materials
            </div>
            <div className='section-desc'>
                <p>We understand that the foundation of any good product lies in using the best materials. As experienced leather crafters, we know how to select the best leather for the job - and the perfect tools to get that job done. </p>
            </div>
            
            {/* Experience */}
            <div className='section-header'>
                <Happy className='header-icon'/> An Experience
            </div>
            <div className='section-desc'>
                Our kits aren't just products, they are experiences. We hope our beginner and intermediate level kits are your entrance to this fulfilling and wonderful hobby.
            </div>
        </div>

        <div className='background-image-container'>
            <img className='background-image' alt='about-background' src={AboutBackground}/>
        </div>

    </div>)
}

export default About