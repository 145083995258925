import { Routes, Route, useLocation } from 'react-router-dom'
import { useState, useEffect } from 'react'
import './App.scss'

// Import firebase file to initialize database
// eslint-disable-next-line
import { db, app} from './Firebase.js'

// Import components
import Header from './components/Header.js'
import Footer from './components/Footer.js'
import Protected from './components/Protected.js'

// Import pages
import Landing from './pages/Landing.js'
import Products from './pages/Products.js'
import Product from './pages/Product.js'
import NotFound from './pages/NotFound.js'
import About from './pages/About.js'
import Contact from './pages/Contact.js'
import Checkout from './pages/Checkout.js'
import Login from './pages/Login.js'
import CreateAccount from './pages/CreateAccount.js'
import Account from './pages/Account.js'
import Order from './pages/Order.js'

// Import contexts
import { AuthProvider } from './contexts/Auth.js'

function App() {

  const [cartOpen, setCartOpen] = useState(false)
  const [mobileNavOpen, setMobileNavOpen] = useState(false)
  const location = useLocation()
  
  // If the screen is resized to be larger, this closes mobileNav and
  useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth > 768) {setMobileNavOpen(false)}
      }
  
      // On start, add the above listener
      window.addEventListener('resize', handleResize)
      
      // On exit, remove the listener
      return () => {window.removeEventListener('resize', handleResize)}
  }, [])

  // Allows account page to fade in once loaded
  useEffect(() => {
    document.querySelector('.account-page')?.classList?.add('visible')
  }, [])

  // If mobile nav or cart are open, disable scrolling
  // useEffect(() => {
  //   (cartOpen || mobileNavOpen) ?
  //   document.body.classList.add('disable-scroll') :
  //   document.body.classList.remove('disable-scroll')
  // }, [cartOpen, mobileNavOpen])

  return (
    <div className='app'>
      
      {/* Don't show header on certain pages */}
      {location.pathname !== '/checkout' && 
       location.pathname !== '/login' &&
       location.pathname !== '/create-account' && 
        <Header 
          cartOpen={cartOpen}
          setCartOpen={setCartOpen}
          mobileNavOpen={mobileNavOpen}
          setMobileNavOpen={setMobileNavOpen}
        />
      }

      <div className='page'>
        <AuthProvider>
          <Routes>
            <Route
              path='/'
              element={<Landing/>}
            />
            <Route
              path='/products'
              element={<Products setCartOpen={setCartOpen}/>}
            />
            <Route
              path='/products/:product/:material'
              element={<Product setCartOpen={setCartOpen}/>}
            />
            <Route
              path='/about'
              element={<About/>}
            />
            <Route
              path='/contact'
              element={<Contact/>}
            />
            <Route
              path='/checkout'
              element={<Checkout/>}
            />
            <Route
              path='/login'
              element={<Login/>}
            />
            <Route
              path='/create-account'
              element={<CreateAccount/>}
            />
            <Route
              path='/account'
              element={<Protected><Account/></Protected>}
            />
            <Route
              path='/order/:session_id'
              element={<Order/>}
            />
            <Route
              path='*'
              element={<NotFound/>}
            />
          </Routes>
        </AuthProvider>
      </div>
    <Footer/>
  </div>
  )
}

export default App;